import styles from './StatusChip.module.css';
import WarningIcon from '@mui/icons-material/WarningAmberOutlined';
import ErrorIcon from '@mui/icons-material/ErrorOutlineOutlined';
import DoneIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { CHIP_TYPE, CHIP_ICON_POS } from '@types';

interface ChipProps {
	text: string;
	type?: CHIP_TYPE;
	showIcon?: boolean;
	CustomIcon?: React.ComponentType<{ [key:string]: string }>;
	customIconColor?: string;
	iconPos?: CHIP_ICON_POS;
	iconOnly?: boolean;
	rounded?: boolean;
	collapse?: boolean;
	stretch?: boolean;
	centerText?: boolean;
}

export const StatusChip = (props: ChipProps) => {
	const {
		text,
		type = CHIP_TYPE.INFO,
		showIcon = false,
		CustomIcon = null,
		customIconColor = '',
		iconPos = CHIP_ICON_POS.LEFT,
		iconOnly = false,
		rounded = false,
		collapse = false,
		stretch = false,
		centerText = false
	} = props;
	const getTypeClass = () => {
		switch (type) {
		case CHIP_TYPE.ERROR:
			return styles.error;
		case CHIP_TYPE.SUCCESS:
			return styles.success;
		case CHIP_TYPE.WARN:
			return styles.warn;
		case CHIP_TYPE.NEUTRAL:
			return styles.neutral;
		case CHIP_TYPE.NEW:
			return styles.new;
		default:
			return styles.info;
		}
	}
	const renderIcon = () => {
		if (CustomIcon !== null) {
			return <CustomIcon data-testid={'@InfoChip-CustomIcon'} className={iconPos === CHIP_ICON_POS.LEFT ? styles.iconLeft : styles.iconRight} color={customIconColor} />
		}
		switch (type) {
		case CHIP_TYPE.ERROR:
			return <ErrorIcon data-testid={'@InfoChip-ErrorIcon'} className={iconPos === CHIP_ICON_POS.LEFT ? styles.iconLeft : styles.iconRight} />;
		case CHIP_TYPE.SUCCESS:
			return <DoneIcon data-testid={'@InfoChip-SuccessIcon'} className={iconPos === CHIP_ICON_POS.LEFT ? styles.iconLeft : styles.iconRight} />;
		case CHIP_TYPE.WARN:
			return <WarningIcon data-testid={'@InfoChip-WarnIcon'} className={iconPos === CHIP_ICON_POS.LEFT ? styles.iconLeft : styles.iconRight} />;
		case CHIP_TYPE.NEUTRAL:
			return <></>;
		case CHIP_TYPE.NEW:
			return <></>;
		default:
			return <InfoIcon data-testid={'@InfoChip-InfoIcon'} className={iconPos === CHIP_ICON_POS.LEFT ? styles.iconLeft : styles.iconRight} />;
		}
	}
	const buildStyles = () => {
		const css: { [key: string]: any } = {};
		css['display'] = 'flex';
		css['width'] = 'fit-content';
		if (stretch) {
			css['flex'] = 1;
			css['width'] = '100%';
		}
		return css;
	}
	return (
		<>
			{iconOnly && <div data-testid={'@InfoChip-IconOnly'} className={`${styles.iconOnlyContainer} ${getTypeClass()}`}>
				{renderIcon()}
			</div>}
			{!iconOnly && <div style={buildStyles()}>
				<div data-testid={'@InfoChip'} className={`${styles.container} ${getTypeClass()} ${rounded ? styles.isRounded : ''} ${collapse ? styles.collapse : ''} ${stretch ? styles.stretch : ''} ${centerText && styles.centerText}`}>
					{showIcon && iconPos === CHIP_ICON_POS.LEFT && <>
						{renderIcon()}
					</>}
					<p>{text}</p>
					{showIcon && iconPos === CHIP_ICON_POS.RIGHT && <>
						{renderIcon()}
					</>}
				</div>
			</div>}
		</>
	);
}

export const InfoChip = (props: ChipProps) => {
	return (
		<StatusChip
			type={CHIP_TYPE.INFO}
			showIcon
			customIconColor={'var(--func-blue-dark)'}
			{...props}
		/>
	)
}

export const SuccessChip = (props: ChipProps) => {
	return (
		<StatusChip
			type={CHIP_TYPE.SUCCESS}
			showIcon
			customIconColor={'var(--txt-success)'}
			{...props}
		/>
	)
}

export const WarnChip = (props: ChipProps) => {
	return (
		<StatusChip
			type={CHIP_TYPE.WARN}
			showIcon
			customIconColor={'var(--txt-warning)'}
			{...props}
		/>
	)
}

export const ErrorChip = (props: ChipProps) => {
	return (
		<StatusChip
			type={CHIP_TYPE.ERROR}
			showIcon
			customIconColor={'var(--txt-error)'}
			{...props}
		/>
	)
}
