import axios from 'axios';

const {
	protocol,
	hostname
} = window.location;


const refreshToken = async () => {
	try {
		const currentRefreshToken = localStorage.getItem('refreshToken');
		const response = await axios.post('/user/refresh', { refresh_token: currentRefreshToken, });
		const newAccessToken = response.data.accessToken;
		localStorage.setItem('jwt', newAccessToken);
		return newAccessToken;
	} catch (error) {
		console.error('Failed to refresh token:', error);
		localStorage.removeItem('jwt');
		localStorage.removeItem('refreshToken');
		const base = `${protocol}//${hostname}`;
		if (window.location.pathname !== '/login') {
			window.location.href = `${base}`;
		}
	}
}

// const ApiClient = axios.create({ baseURL: import.meta.env.PROD ? import.meta.env.VITE_API_URL : 'http://localhost:3000' });
const ApiClient = axios.create();

ApiClient.interceptors.request.use((config) => {
	const token = localStorage.getItem('jwt');
	if (token) {
		config.headers = config.headers || {};
		config.headers['Authorization'] = `Bearer ${token}`;
	}

	return config;
}, (error) => {
	if (error.code === 'ERR_NETWORK') {
		return {
			isError: true,
			status: -1,
			code: error.code,
			message: error.message
		}
	}
	return Promise.reject(error);
});

ApiClient.interceptors.response.use((response) => {
	const { data } = response;
	if (data.status && data.status === 302) {
		if (
			(data.error && data.error === 'Not Authenticated') ||
			(data.message && data.message === 'Not Authenticated')
		) {
			const base = `${protocol}//${hostname}`;
			if (window.location.pathname !== '/login') {
				window.location.href = `${base}`;
			}
		}
	}
	return response
}, async (error) => {
	const originalRequest = error.config;

	if (error.response.status === 401 && !originalRequest._retry) {

		originalRequest._retry = true;

		try {
			// Assuming `refreshToken()` is a function that refreshes your token
			const newToken = await refreshToken();

			ApiClient.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
			originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
			return ApiClient(originalRequest);
		} catch (refreshError) {
			console.log(refreshError);
			localStorage.removeItem('jwt');
			localStorage.removeItem('refreshToken');
			if (window.location.pathname !== '/login') {
				window.location.href = `${protocol}//${hostname}`;
			}
		}
	}

	return Promise.reject({
		isError: true,
		status: error.response.status,
		code: error.code,
		message: error.message,
		response: error.response
	});
});

export default ApiClient;
