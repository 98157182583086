export const clearPendoIdentify = () => {
	localStorage.removeItem('pendo');
	if ((window as any).pendo) {
		(window as any).pendo.identify({
			visitor: {
				id: 0,
				email: '',
				slug: ''
			},

			account: { id: 'ACCOUNT-UNIQUE-ID', }
		});
	}
}
