import {
	useState,
	useRef,
	useEffect,
	forwardRef,
	FocusEvent,
	ChangeEvent,
	KeyboardEvent
} from 'react';
import { createPortal } from 'react-dom';
import styles from './Datepicker.module.scss';
import { Text, Caption, Spacer } from '@components';
import { useDatePicker, DPDay } from '@rehookify/datepicker';
import ArrowBack from '@mui/icons-material/ArrowBackIos';
import ArrowForward from '@mui/icons-material/ArrowForwardIos';
import CalendarIcon from '@mui/icons-material/CalendarToday';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonthOutlined';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import formatDate from 'date-fns/format';
import {
	VALIDATION_ERROR, PartialInputEvent, TObject, TEXT_WEIGHT, SPACER_DIRECTION
} from '@types';
import { ZodObject } from 'zod';

type DatepickerProps = {
	id?: string;
	closeOnSelect?: boolean;
	disabled?: boolean;
	disableFuture?: boolean;
	disablePast?: boolean;
	label?: string;
	maxDate?: Date;
	minDate?: Date;
	onChange: (e:Date | string | PartialInputEvent | Date[] | string[]) => void;
	open?: boolean;
	value: Date | string | Date[] | string[];
	autoFocus?: boolean;
	onOpen?: () => void;
	onClose?: () => void;
	dateOnly?: boolean;
	timeOnly?: boolean;
	placeholder?: string;
	format?: string;
	canAcceptReject?: boolean;
	onAccept?: () => void;
	onReject?: () => void;
	required?: boolean;
	stretch?: boolean;
	onBlur?: (e?:FocusEvent<HTMLInputElement>) => void;
	tabIndex?: number;
	displayThin?: boolean;
	onFocus?: () => void;
	useLabelSpace?: boolean;
	name: string;
	returnNotSynthetic?: boolean;
	returnAsFormatedString?: boolean;
	hasError?: boolean;
	errorMessage?: string;
	onValidationError?: (errors:VALIDATION_ERROR, clear:boolean) => void;
	onValidationSuccess?: (errors:VALIDATION_ERROR, clear:boolean) => void;
	keepErrorSpace?: boolean;
	validations?: ZodObject<any>;
	/*
	 * dateRange?: boolean;
	 * timeRange?: boolean;
	 */
}

type DTParts = {
	empty: boolean;
	theDay: string;
	theMonth: string;
	theYear: string;
	theHour: string;
	theMin: string;
}

const CoreDatepicker = forwardRef((props:DatepickerProps, _ref) => {
	const {
		id = 'date-picker',
		closeOnSelect = true,
		disabled = false,
		disableFuture = false,
		disablePast = false,
		label,
		maxDate,
		minDate,
		onChange,
		open,
		value,
		autoFocus = false,
		onOpen,
		onClose,
		dateOnly = false,
		timeOnly = false,
		placeholder,
		format,
		canAcceptReject = false,
		onAccept,
		onReject,
		required = false,
		stretch = false,
		onBlur,
		tabIndex,
		displayThin = false,
		onFocus,
		useLabelSpace,
		name,
		returnNotSynthetic,
		returnAsFormatedString = false,
		hasError,
		errorMessage,
		onValidationError,
		onValidationSuccess,
		keepErrorSpace = true,
		validations,
		/*
		 * dateRange = false,
		 * timeRange = false
		 */
	} = props;
	const dateRange = false;
	const timeRange = false;

	const pickerRef = useRef<HTMLDivElement>(null);
	const pickerInputRef = useRef<HTMLDivElement>(null);
	const calRef = useRef<HTMLDivElement>(null);
	const calContainerRef = useRef<HTMLDivElement>(null);
	const headerRef = useRef<HTMLDivElement>(null);
	const hourRef = useRef<HTMLButtonElement>(null);
	const minRef = useRef<HTMLButtonElement>(null);
	const hourContainerRef = useRef<HTMLDivElement>(null);
	const minContainerRef = useRef<HTMLDivElement>(null);
	const dateInputRef = useRef<HTMLInputElement>(null);
	const timeInputRef = useRef<HTMLInputElement>(null);
	const isTouched = useRef<boolean>(false);
	const [isActive, setIsActive] = useState<boolean>(false);
	const [activeTab, setActiveTab] = useState<'date' | 'time'>(timeOnly ? 'time' : 'date');
	const [isAboveFold, setIsAboveFold] = useState<boolean>(true);
	const [calHeight, setCalHeight] = useState<number>(0);
	const [pickerVal, setPickerVal] = useState<string | string[]>(dateRange ? ['', ''] : '');
	const [timePickerVal, setTimePickerVal] = useState<string | string[]>(timeRange ? ['', ''] : '');
	const [isOpen, setIsOpen] = useState<boolean | undefined>(open);
	const [selectedDates, onDatesChange] = useState<Date[]>([]);
	const [, setAutoFocusField] = useState<boolean>(autoFocus);
	const [hourVal, setHourVal] = useState<string | string[]>('');
	const [minVal, setMinVal] = useState<string | string[]>('');
	const [containerPos, setContainerPos] = useState<DOMRect | null>(null);
	const [showWidget, setShowWidget] = useState<string>('');
	const [showMonthPanel, setShowMonthPanel] = useState<boolean>(false);
	const [showYearPanel, setShowYearPanel] = useState<boolean>(false);
	const [offsetDate, onOffsetChange] = useState<Date>(new Date());
	const [touched, setTouched] = useState<boolean>(false);
	const fieldFocus = useRef<number>(-1);
	const hours = [...Array(24).keys()];
	const mins = [...Array(60).keys()];

	const inputProps = () => {
		const newProps = { ...props };
		delete newProps.closeOnSelect;
		delete newProps.disableFuture;
		delete newProps.disablePast;
		delete newProps.maxDate;
		delete newProps.minDate;
		// delete newProps.value;
		delete newProps.autoFocus;
		delete newProps.onOpen;
		delete newProps.onClose;
		delete newProps.dateOnly;
		delete newProps.timeOnly;
		delete newProps.format;
		delete newProps.canAcceptReject;
		delete newProps.onAccept;
		delete newProps.onReject;
		delete newProps.stretch;
		delete newProps.displayThin;
		delete newProps.useLabelSpace;
		delete newProps.returnNotSynthetic;
		delete newProps.returnAsFormatedString;
		delete newProps.hasError;
		delete newProps.errorMessage;
		delete newProps.onValidationError;
		delete newProps.onValidationSuccess;
		delete newProps.keepErrorSpace;
		delete newProps.validations;
		/*
		 * delete newProps.dateRange;
		 * delete newProps.timeRange;
		 */
		return newProps;
	}

	const {
		data: { calendars, weekDays, formattedDates, months, years },
		propGetters: {
			addOffset,
			subtractOffset
		}
	} = useDatePicker({
		selectedDates,
		onDatesChange,
		offsetDate,
		onOffsetChange,
		dates: {
			toggle: true,
			mode: dateRange ? 'range' : 'multiple',
			minDate: disablePast ? new Date(new Date()) : minDate,
			maxDate: disableFuture ? new Date(new Date()) : maxDate
		},
		calendar: { offsets: [1] },
		locale: {
			hour: '2-digit',
			minute: '2-digit',
			weekday: 'narrow',
			monthName: 'short'
		}
	});
	const [
		{ month, year, days },
		{ month: month2, year: year2, days: days2 }
	] = calendars;
	const buildDayClasses = (day:DPDay) => {
		const classes = [styles.day];
		day.inCurrentMonth ? classes.push(styles.currentMonthDay) : classes.push(styles.nonMonthDay);
		if (day.now) {
			classes.push(styles.highlightToday);
		}
		if (day.selected) {
			classes.push(styles.selectedDay);
		}
		return classes.join(' ');
	}
	const dateTimeParts = (t: 'date' | 'time', index: number | null = null): DTParts => {
		const now = new Date();
		if (
			(`${pickerVal}` === '' && `${timePickerVal}` === '') ||
			(pickerVal[0] === '' && pickerVal[1] === '') ||
			(timePickerVal[0] === '' && timePickerVal[1] === '')
		) {
			return {
				empty: true,
				theDay: '',
				theMonth: '',
				theYear: '',
				theHour: '',
				theMin: ''
			}
		}
		if (t === 'date') {
			const [theDay, theMonth, theYear] = index ? pickerVal[index].split('/') : `${pickerVal}`.split('/');
			return {
				empty: false,
				theDay: theDay ? theDay : '',
				theMonth: theMonth ? theMonth : '',
				theYear: theYear ? theYear : '',
				theHour: '',
				theMin: ''
			};
		} else if (t === 'time') {
			const [theHour, theMin] = index ? (timePickerVal[index] ?? '').split(':') : `${timePickerVal}`.split(':');
			return {
				empty: false,
				theDay: '',
				theMonth: '',
				theYear: '',
				theHour: theHour ? theHour : '',
				theMin: theMin ? theMin : ''
			};
		} else {
			const [theDay, theMonth, theYear] = (index ? pickerVal[index] : pickerVal as string ?? []).split('/');
			const [theHour, theMin] = timePickerVal ? (index ? timePickerVal[index] : timePickerVal as string ?? []).split(':') : ['00', '00'];
			return {
				empty: false,
				theDay: theDay ? theDay : (now.getDate() <= 9 ? `0${now.getDate()}` : `${now.getDate()}`),
				theMonth: theMonth ? theMonth : (now.getMonth() <= 9 ? `0${now.getMonth() + 1}` : `${now.getMonth() + 1}`),
				theYear: theYear ? theYear : `${now.getFullYear()}`,
				theHour,
				theMin
			};
		}
	}
	const dateFromParts = (parts: DTParts, withTime: boolean): Date => {
		const now = new Date();
		if (withTime) {
			return new Date(
				isNaN(parseInt(`${parts.theYear}`)) ? now.getFullYear() : parseInt(`${parts.theYear}`),
				isNaN(parseInt(`${parts.theMonth}`)) ? now.getMonth() - 1 : parseInt(`${parts.theMonth}`) - 1,
				isNaN(parseInt(`${parts.theDay}`)) ? now.getDate() : parseInt(`${parts.theDay}`),
				isNaN(parseInt(`${parts.theHour}`)) ? now.getHours() : parseInt(`${parts.theHour}`),
				isNaN(parseInt(`${parts.theMin}`)) ? now.getMinutes() : parseInt(`${parts.theMin}`),
			);
		} else {
			return new Date(
				isNaN(parseInt(`${parts.theYear}`)) ? now.getFullYear() : parseInt(`${parts.theYear}`),
				isNaN(parseInt(`${parts.theMonth}`)) ? now.getMonth() - 1 : parseInt(`${parts.theMonth}`) - 1,
				isNaN(parseInt(`${parts.theDay}`)) ? now.getDate() : parseInt(`${parts.theDay}`)
			);
		}
	}
	const updatePicker = (part: 'date' | 'time', index: number | null = null) => {
		if ((index === null && part === 'date') || (index !== null && index >= 0 && dateRange)) {
			const parts = dateTimeParts('date', index);
			if (!parts.empty) {
				const timeParts = dateTimeParts('time', index);
				const now = new Date();
				let theDate = new Date(
					isNaN(parseInt(`${parts.theYear}`)) ? now.getFullYear() : parseInt(`${parts.theYear}`),
					isNaN(parseInt(`${parts.theMonth}`)) ? now.getMonth() - 1 : parseInt(`${parts.theMonth}`) - 1,
					isNaN(parseInt(`${parts.theDay}`)) ? now.getDate() : parseInt(`${parts.theDay}`)
				);
				if (!timeParts.empty && !dateRange) {
					theDate = new Date(
						isNaN(parseInt(`${parts.theYear}`)) ? now.getFullYear() : parseInt(`${parts.theYear}`),
						isNaN(parseInt(`${parts.theMonth}`)) ? now.getMonth() - 1 : parseInt(`${parts.theMonth}`) - 1,
						isNaN(parseInt(`${parts.theDay}`)) ? now.getDate() : parseInt(`${parts.theDay}`),
						isNaN(parseInt(`${timeParts.theHour}`)) ? now.getHours() : parseInt(`${timeParts.theHour}`),
						isNaN(parseInt(`${timeParts.theMin}`)) ? now.getMinutes() : parseInt(`${timeParts.theMin}`),
					);
				}
				if (dateRange) {
					let d1, d2;
					if (index === 0) {
						d1 = theDate;
						d2 = dateFromParts(dateTimeParts('date', 1), false);
					} else {
						d1 = dateFromParts(dateTimeParts('date', 0), false);
						d2 = theDate;
					}
					onDatesChange([d1, d2]);
				} else {
					onDatesChange([theDate]);
				}
			}
		} else if ((index === null && part === 'time') || (index !== null && index >= 0 && timeRange)) {
			const parts = dateTimeParts('time', index);
			setHourVal(parts.theHour);
			setMinVal(parts.theMin);
			setTimeout(() => {
				if (hourContainerRef.current) {
					hourContainerRef.current.scrollTo({ top: parseInt(parts.theHour) * 25 });
				}
				if (minContainerRef.current) {
					minContainerRef.current.scrollTo({ top: parseInt(parts.theMin) * 25 });
				}
			}, 0);
		}
	}
	const updatePickerPos = () => {
		if (pickerInputRef?.current) {
			setContainerPos(pickerInputRef?.current.getBoundingClientRect());
		}
	}
	const toggleDPOpen = (b:boolean, part: 'date' | 'time') => {
		fieldFocus.current = part === 'date' ? 0 : 1;
		updatePickerPos();
		setIsActive(b);
		if (pickerRef.current) {
			setIsAboveFold(window.innerHeight - pickerRef.current.getBoundingClientRect().y - pickerRef.current.offsetHeight - 300 > 0);
		}
		setIsOpen(b);
		if (onOpen && b) onOpen();
		if (onClose && !b) onClose();
		if (b) updatePicker(part, dateRange || timeRange ? fieldFocus.current : null);
		part === 'date' || dateRange ? setActiveTab('date') : setActiveTab('time');
	}
	const withLeading0 = (t:number | string) => {
		return `${t}`.length === 1 ? `0${t}` : t;
	}
	const updateTime = (t:string, b:boolean) => {
		b ? setHourVal(t) : setMinVal(t);
		if (timeOnly) {
			let newTime;
			if (pickerVal === '') {
				newTime = b ? `${withLeading0(t)}:${withLeading0(minVal as string) || '00'}` : `${withLeading0(hourVal as string) || '00'}:${withLeading0(t)}`;
			} else {
				const [hour, min] = `${pickerVal}`.split(':');
				newTime = b ? `${withLeading0(t)}:${min || '00'}` : `${hour || '00'}:${withLeading0(t)}`;
			}
			setTimePickerVal(newTime);
			if (returnNotSynthetic) {
				onChange(newTime);
			} else {
				const timeParts = newTime.split(':');
				const now = new Date();
				const retObj:PartialInputEvent = {
					target: {
						name: name,
						value: returnAsFormatedString ? newTime : new Date(now.getFullYear(), now.getMonth() - 1, now.getDate(), parseInt(timeParts[0]), parseInt(timeParts[1]))
					}
				}
				onChange(retObj);
			}
		} else if (timeRange) {
			let t1, t2;
			const index = fieldFocus.current;
			if ((index === 0 && timePickerVal[0] === '') || (index === 1 && timePickerVal[1] === '')) {
				if (index === 0) {
					t1 = b ? `${withLeading0(t)}:00` : `00:${withLeading0(t)}`;
					t2 = timePickerVal[1] ?? '';
				} else {
					t1 = timePickerVal[0] ?? '';
					t2 = b ? `${withLeading0(t)}:00` : `00:${withLeading0(t)}`;
				}
			} else if (index === 0) {
				const [hour, min] = `${timePickerVal[0]}`.split(':');
				t1 = b ? `${withLeading0(t)}:${min || '00'}` : `${hour || '00'}:${withLeading0(t)}`;
				t2 = timePickerVal[1] ?? '';
			} else {
				const [hour, min] = `${timePickerVal[1]}`.split(':');
				t1 = timePickerVal[0] ?? '';
				t2 = b ? `${withLeading0(t)}:${min || '00'}` : `${hour || '00'}:${withLeading0(t)}`;
			}
			setTimePickerVal([t1, t2]);
			onChange([t1, t2]);
		} else {
			const [theDate] = selectedDates;
			b ? theDate.setHours(+t) : theDate.setMinutes(+t);
			onDatesChange([theDate]);
		}
		if (!isTouched.current) isTouched.current = true;
	}
	const dateFormatter = (v:string) => {
		const cleaned = (`${v}`).replace(/\D/g, '');
		const match = cleaned.match(/^(\d{0,2})?(\d{0,2})?(\d{0,4})?/);
		if (match) {
			return [
				match[1],
				match[2] ? '/': '',
				match[2],
				match[3] ? '/': '',
				match[3]
			].join('');
		}
		return v;
	}
	const timeFormatter = (v:string) => {
		let cleaned = (`${v}`).replace(/\D/g, '');
		if (parseInt(cleaned.charAt(0)) >= 3) {
			cleaned = `0${cleaned}`;
		}
		const match = cleaned.match(/^(\d{0,2})?(\d{0,2})?/);
		if (match) {
			return [
				match[1],
				match[2] ? ':' : '',
				match[2]
			].join('');
		}
		return v;
	}
	const returnDateTime = () => {
		const retVal = `${pickerVal} ${timePickerVal}`;
		returnNotSynthetic ? onChange(retVal) : onChange({
			target: {
				name,
				value: retVal
			}
		});
	}
	const updatePickerVal = (e:ChangeEvent<HTMLInputElement>, index: number | null = null) => {
		const formatted = dateFormatter(e.target.value);
		const datesInRange = [];
		if (dateRange) {
			let date1, date2;
			if (index === 0) {
				date1 = formatted;
				[, date2] = pickerVal;
			} else {
				[date1] = pickerVal;
				date2 = formatted;
			}
			datesInRange.push(date1 ?? '');
			datesInRange.push(date2 ?? '');
			setPickerVal(datesInRange);
		} else {
			setPickerVal(formatted);
		}
		if (dateOnly) {
			returnNotSynthetic ? onChange(formatted) : onChange(e);
		} else if (dateRange) {
			onChange(datesInRange);
		} else {
			returnDateTime();
		}
		if (e.target.value === '') {
			if (!dateRange) {
				onDatesChange([]);
			} else if (index) {
				onDatesChange(selectedDates.splice(index, 1))
			}
		}
		if (formatted.length === 10) {
			const dateParts = formatted.split('/');
			if (!dateRange) {
				onDatesChange([new Date(parseInt(dateParts[2]), parseInt(dateParts[1]) - 1, parseInt(dateParts[0]))]);
			} else if (index) {
				let date1, date2;
				const dateParts1 = formatted.split('/');
				const dateParts2 = index === 0 ? pickerVal[1].split('/') : pickerVal[0].split('/');
				if (index === 0) {
					date1 = new Date(parseInt(dateParts1[2]), parseInt(dateParts1[1]) - 1, parseInt(dateParts1[0]));
					date2 = new Date(parseInt(dateParts2[2]), parseInt(dateParts2[1]) - 1, parseInt(dateParts2[0]));
				} else {
					date1 = new Date(parseInt(dateParts2[2]), parseInt(dateParts2[1]) - 1, parseInt(dateParts2[0]));
					date2 = new Date(parseInt(dateParts1[2]), parseInt(dateParts1[1]) - 1, parseInt(dateParts1[0]));
				}
				onDatesChange([date1, date2]);
			}
			if ((!dateOnly || (dateRange && index === 0)) && timeInputRef.current) {
				timeInputRef.current.focus();
				if (!dateRange) setActiveTab('time');
			}
		}
	}
	const updateTimePickerVal = (e:ChangeEvent<HTMLInputElement>, index: number | null = null) => {
		const formatted = timeFormatter(e.target.value);
		const timeParts = formatted.split(':');
		const hoursInRange = [];
		const minsInRange = [];
		if (timeRange) {
			let hour1, hour2, min1, min2;
			if (index === 0) {
				hour1 = `${withLeading0(timeParts[0] ?? '')}`;
				[, hour2] = hourVal;
				min1 = `${withLeading0(timeParts[1] ?? '')}`;
				[, min2] = minVal;
			} else {
				[hour1] = hourVal;
				hour2 = `${withLeading0(timeParts[0] ?? '')}`;
				[min1] = minVal;
				min2 = `${withLeading0(timeParts[1] ?? '')}`;
			}
			hoursInRange.push(hour1 ?? '');
			hoursInRange.push(hour2 ?? '');
			minsInRange.push(min1 ?? '');
			minsInRange.push(min2 ?? '');
			setHourVal(hoursInRange);
			setMinVal(minsInRange);
		} else {
			setHourVal(`${withLeading0(timeParts[0])}`);
			setMinVal(`${withLeading0(timeParts[1])}`);
		}
		let d1, d2;
		if (timeRange) {
			if (index === 0) {
				d1 = formatted;
				d2 = timePickerVal[1] as string;
			} else {
				d1 = timePickerVal[0] as string;
				d2 = formatted;
			}
			setTimePickerVal([d1 ?? '', d2 ?? '']);
		} else {
			setTimePickerVal(formatted);
		}
		if (timeOnly) {
			returnNotSynthetic ? onChange(formatted) : onChange(e);
		} else if (timeRange) {
			onChange([d1 ?? '', d2 ?? '']);
		} else {
			returnDateTime();
		}
		if (hourContainerRef.current) {
			hourContainerRef.current.scrollTo({ top: parseInt(timeParts[0]) * 25 });
		}
		if (minContainerRef.current) {
			minContainerRef.current.scrollTo({ top: parseInt(timeParts[1]) * 25 });
		}
		if (!isTouched.current) isTouched.current = true;
		if (timeRange && fieldFocus.current === 0 && timeInputRef.current && formatted.length === 5) {
			fieldFocus.current = 1;
			timeInputRef.current.focus();
		}
	}
	const switchTab = (part: 'date' | 'time') => {
		setActiveTab(part);
		if (part === 'time') {
			setTimeout(() => {
				if (hourContainerRef.current) {
					hourContainerRef.current.scrollTo({ top: parseInt(hourVal as string) * 25 });
				}
				if (minContainerRef.current) {
					minContainerRef.current.scrollTo({ top: parseInt(minVal as string) * 25 });
				}
			}, 0);
		}
	}
	const updateDates = (d:Date) => {
		if (!dateRange) onDatesChange([d]);
		if (dateRange) {
			let dates = [...selectedDates];
			if (dates.length) {
				dates.push(d);
			} else {
				dates = [d];
			}
			onDatesChange(dates);
		}
		if (closeOnSelect && (dateOnly || timeOnly)) {
			setIsOpen(false);
			if (onClose) {
				onClose();
			}
		}
		if (!dateOnly) {
			if (!dateRange) switchTab('time');
			timeInputRef.current?.focus();
		}
	}
	const setPos = () => {
		const styles:TObject = {}
		if (!isAboveFold) {
			if (calContainerRef?.current && containerPos) {
				styles.transform = `translate3d(${Math.round(containerPos.x)}px,${Math.round(containerPos.y - calContainerRef.current.offsetHeight)}px,0px)`;
			} else if (containerPos) {
				styles.transform = `translate3d(${Math.round(containerPos.x)}px,${Math.round(containerPos.y - 305)}px,0px)`;
			}
		} else if (containerPos) {
			styles.transform = `translate3d(${Math.round(containerPos.x)}px,${Math.round(containerPos.y + containerPos.height)}px,0px)`;
		}
		return styles;
	}
	/*
	 * const setCalContainerHeight = () => {
	 * 	const styles:TObject = {}
	 * 	if (calContainerRef.current) {
	 * 		styles.height = calContainerRef.current.offsetHeight;
	 * 	}
	 * 	return styles;
	 * }
	 */
	const setPlaceholder = (part: 'date' | 'time') => {
		if (placeholder && (dateOnly || timeOnly)) {
			return placeholder;
		}
		if (part === 'date') {
			return 'dd/mm/yyyy';
		}
		if (part === 'time') {
			return 'hh:mm';
		}
	}
	const checkValidations = () => {
		if (validations) {
			const valid = validations.safeParse(value);
			if (!valid.success) {
				const issues = [];
				for (let i=0, l=valid.error.issues.length; i<l; ++i) {
					issues.push(valid.error.issues[i].message);
				}
				const error = {
					field: name,
					issues
				};
				if (onValidationError) {
					onValidationError(error, false);
				}
			} else if (onValidationSuccess) {
				onValidationSuccess({ field: name }, true);
			}
		}
	}
	const triggerBlur = (e:FocusEvent<HTMLInputElement>) => {
		if (onBlur) onBlur(e);
		checkValidations();
	}
	const triggerFocus = () => {
		if (onFocus) onFocus();
		if (!touched) setTouched(true);
	}
	const onDateKeyDown = (e:KeyboardEvent<HTMLInputElement>) => {
		if ((dateOnly && e.key === 'Tab') || (e.shiftKey && e.key === 'Tab')) {
			setIsOpen(false);
			setIsActive(false);
		}
	}
	const onTimeKeyDown = (e:KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Tab') {
			setIsOpen(false);
			setIsActive(false);
		}
		if (!timeOnly && dateInputRef.current) {
			if (timePickerVal.length <= 1 && e.key === 'Backspace') {
				setTimeout(() => {
					dateInputRef?.current?.focus();
				}, 0);
			}
		}
	}
	const handleClickOutside = (e:MouseEvent) => {
		if (isOpen) {
			if (!pickerRef?.current?.contains(e.target as Node) && !calContainerRef?.current?.contains(e.target as Node)) {
				setIsOpen(false);
				setIsActive(false);
				if (onClose) {
					onClose();
				}
			}
		}
	}
	const toggleDatePanelDisplay = () => {
		if (!showMonthPanel && !showYearPanel) setShowMonthPanel(true);
		if (showMonthPanel && !showYearPanel) {
			setShowMonthPanel(false);
			setShowYearPanel(true);
		}
	}
	const getSelectableYearRange = () => {
		const thisYear = new Date().getFullYear();
		const firstYear = thisYear - 5;
		const lastYear = thisYear + 6;
		return `${firstYear} - ${lastYear}`;
	}
	const setNewYear = (year:number) => {
		onOffsetChange(new Date(year, offsetDate.getMonth() - 1, offsetDate.getDate()));
		setShowYearPanel(false);
		setShowMonthPanel(true);
	}
	const setNewMonth = (month:string) => {
		const monthIndex = months.findIndex((m) => m.month === month);
		onOffsetChange(new Date(offsetDate.getFullYear(), monthIndex, offsetDate.getDate()));
		setShowYearPanel(false);
		setShowMonthPanel(false);
	}
	const isHourMin = (type: 'hour' | 'min', checkVal: string | number, index:number): boolean => {
		if (type === 'hour') {
			const isString = typeof hourVal === 'string' && hourVal.indexOf(',') < 0;
			return withLeading0(
				isString ? hourVal as string :
					hourVal.indexOf(',') < 0 ? hourVal[fieldFocus.current] :
						(hourVal as string).split(',')[index]
			) === withLeading0(`${checkVal}`);
		} else {
			const isString = typeof minVal === 'string' && minVal.indexOf(',') < 0;
			return withLeading0(
				isString ? minVal as string :
					minVal.indexOf(',') < 0 ? minVal[fieldFocus.current] :
						(minVal as string).split(',')[index]
			) === withLeading0(`${checkVal}`);
		}
	}

	useEffect(() => {
		if (selectedDates.length > 0) {
			let [theDate1] = formattedDates;
			const [, theDate2] = formattedDates;
			if (!dateOnly && !dateRange) {
				theDate1 = `${theDate1} ${(withLeading0(hourVal as string) || '00')}:${(withLeading0(minVal as string) || '00')}`;
			}
			if (returnNotSynthetic || dateRange) {
				if (!dateRange) {
					onChange(theDate1);
				} else {
					onChange([theDate1, theDate2 ?? '']);
				}
			} else {
				const retObj:PartialInputEvent = {
					target: {
						name: name,
						value: returnAsFormatedString ? theDate1 : new Date(selectedDates[0])
					}
				}
				onChange(retObj);
			}
		}
	}, [selectedDates]); // eslint-disable-line
	useEffect(() => {
		if (isOpen) {
			document.removeEventListener('mousedown', handleClickOutside);
			document.addEventListener('mousedown', handleClickOutside);
		} else {
			document.removeEventListener('mousedown', handleClickOutside);
		}
		document.addEventListener('mousedown', handleClickOutside);
		return (() => {
			document.removeEventListener('mousedown', handleClickOutside);
		});
	}, [pickerRef, isOpen]); // eslint-disable-line
	useEffect(() => {
		if (autoFocus) {
			setAutoFocusField(autoFocus);
		}
	}, [autoFocus]);
	// eslint-disable-next-line
	useEffect(() => {
		if (calRef?.current) {
			setCalHeight(calRef?.current.offsetHeight);
		}
	});
	useEffect(() => {
		if (!dateOnly) {
			if (hourContainerRef && hourContainerRef.current && hourRef && hourRef.current) {
				hourContainerRef.current.scrollTop = (hourRef?.current?.getBoundingClientRect().y - hourContainerRef?.current?.getBoundingClientRect().y) - 100;
			}
			if (minContainerRef && minContainerRef.current && minRef && minRef.current) {
				minContainerRef.current.scrollTop = (minRef?.current?.getBoundingClientRect().y - minContainerRef?.current?.getBoundingClientRect().y) - 100;
			}
		}
		if (!timeOnly) {
			const now = new Date();
			const nowYear = now.getFullYear();
			const nowMonth = now.getMonth();
			const nowDate = now.getDate();
			if (pickerVal === '') {
				onOffsetChange(new Date(nowYear, nowMonth, nowDate));
			} else {
				const parts = dateTimeParts('date');
				onOffsetChange(new Date(
					parseInt(`${parts.theYear}`),
					parseInt(`${parts.theMonth}`) - 1,
					parseInt(`${parts.theDay}`)
				));
			}
		}
		const timeout = setTimeout(() => {
			if (isOpen) {
				setShowWidget(styles.showMe);
			} else {
				setShowWidget('');
			}
		}, 250);
		if (dateRange) onOffsetChange(new Date());
		if (!touched && isOpen) setTouched(true);
		return (() => {
			clearTimeout(timeout);
		});
	}, [isOpen]); // eslint-disable-line
	useEffect(() => {
		if (value instanceof Date) {
			if (!dateOnly && !timeOnly) {
				setPickerVal(formatDate(value, format ?? 'dd/MM/yyyy'));
				if (isTouched.current) setTimePickerVal(formatDate(value, format ?? 'HH:mm'));
			} else if (dateOnly) {
				setPickerVal(formatDate(value, format ?? 'dd/MM/yyyy'));
			} else if (timeOnly) {
				setTimePickerVal(formatDate(value, format ?? 'HH:mm'));
			}
		} else if (typeof value === 'string' && value === '') {
			setPickerVal('');
			setTimePickerVal('');
		} else if (typeof value === 'string' && value !== '') {
			if (timeOnly) {
				const formatted = timeFormatter(value);
				const timeParts = formatted.split(':');
				setHourVal(`${withLeading0(timeParts[0])}`);
				setMinVal(`${withLeading0(timeParts[1])}`);
				setTimePickerVal(formatted);
			}
			if (dateOnly) setPickerVal(value);
		} else if (Array.isArray(value)) {
			if (dateRange) {
				setPickerVal(value as string[]);
			} else if (timeRange) {
				setTimePickerVal(value as string[]);
			}
		}
		checkValidations();
	}, [value]); // eslint-disable-line
	useEffect(() => {
		const updateOnScroll = () => {
			updatePickerPos();
		}
		window.addEventListener('scroll', updateOnScroll);
		return (() => {
			window.removeEventListener('scroll', updateOnScroll);
		});
	}, []);

	return (
		<div className={`${styles.container} ${stretch ? styles.flex : ''}`} ref={pickerRef} onClick={() => updatePickerPos()}>
			{label && <label className={styles.label}>{label}{required ? <span className={styles.required}> *</span> : <></>}</label>}
			{!label && useLabelSpace && <label className={styles.labelTransparent} htmlFor={id}>{name}{required ? <span className={styles.required}> *</span> : <></>}</label>}
			<div className={`${styles.inputContainer} ${hasError ? styles.hasError : ''} ${displayThin ? styles.displayThin : ''} ${isActive ? styles.activeInput : ''} ${disabled ? styles.disabledInput : ''}`} ref={pickerInputRef}>
				{!timeOnly && <input
					{...inputProps()}
					className={`${styles.input} ${!dateOnly && styles.multiInput} ${timeRange && timePickerVal[0] !== '' && styles.multiTime} ${timeRange && timePickerVal[0] === '' && styles.multiTimeEmpty}`}
					type={'text'}
					onChange={(e:ChangeEvent<HTMLInputElement>) => {
						if (timeRange) {
							updateTimePickerVal(e, 0);
						} else if (dateRange) {
							updatePickerVal(e, 0);
						} else {
							updatePickerVal(e);
						}
					}}
					value={
						timeRange ? timePickerVal[0] :
							dateRange ? pickerVal[0] :
								pickerVal
					}
					onFocus={() => toggleDPOpen(true, 'date')}
					disabled={disabled}
					ref={dateInputRef}
					placeholder={setPlaceholder(timeRange ? 'time' : 'date')}
					onBlur={(e) => triggerBlur(e)}
					tabIndex={tabIndex}
					onKeyDown={(e) => onDateKeyDown(e)}
					onFocusCapture={() => triggerFocus()}
					name={name}
				/>}
				{(dateRange || timeRange) && <div className={styles.rangeSplit}>-</div>}
				{!dateOnly && <input
					{...inputProps()}
					className={styles.input}
					type={'text'}
					onChange={(e:ChangeEvent<HTMLInputElement>) => {
						if (dateRange) {
							updatePickerVal(e, 1);
						} else if (timeRange) {
							updateTimePickerVal(e, 1);
						} else {
							updateTimePickerVal(e);
						}
					}}
					value={
						dateRange ? pickerVal[1] :
							timeRange ? timePickerVal[1] :
								timePickerVal
					}
					onFocus={() => toggleDPOpen(true, 'time')}
					disabled={disabled}
					ref={timeInputRef}
					placeholder={setPlaceholder(dateRange ? 'date' : 'time')}
					onBlur={(e) => triggerBlur(e)}
					tabIndex={tabIndex ? tabIndex + 1 : tabIndex}
					onKeyDown={(e) => onTimeKeyDown(e)}
					onFocusCapture={() => triggerFocus()}
					name={name}
				/>}
				{canAcceptReject && <>
					<button className={`${styles.acceptRejectBtn} ${styles.accept}`} onClick={() => { if (onAccept) onAccept() }} aria-label={`Datepicker Accept Button`}><DoneIcon /></button>
					<button className={`${styles.acceptRejectBtn} ${styles.reject}`} onClick={() => { if (onReject) onReject() }} aria-label={`Datepicker Reject Button`}><ClearIcon /></button>
				</>}
				{!timeOnly && !timeRange && <CalendarIcon className={`${styles.inputIcon} ${disabled ? styles.disabledIcon : ''}`} onClick={() => { !disabled && toggleDPOpen(!isOpen, 'date') }} />}
				{(timeOnly || timeRange) && <AccessTimeIcon className={`${styles.inputIcon} ${disabled ? styles.disabledIcon : ''}`} onClick={() => { !disabled && toggleDPOpen(!isOpen, 'time') }} />}
			</div>
			{hasError && <div className={styles.errorMsgContainer}>
				<Spacer size={5} />
				<Caption color={'var(--txt-error)'}>
					{errorMessage}
				</Caption>
			</div>}
			{keepErrorSpace && !hasError && <div className={styles.errorMsgContainer} />}
			{isOpen && createPortal(<div className={`${styles.calFullContainer} ${showWidget}`} style={setPos()}>
				{/* <div className={`${styles.calContainer}`} style={setCalContainerHeight()} ref={calContainerRef}> */}
				<div className={`${styles.calContainer}`} ref={calContainerRef}>
					<div>
						<header className={styles.calHeader}>
							{!dateOnly && !timeOnly && !dateRange && !timeRange && <div className={styles.dateTimeTabs}>
								<button
									className={`${styles.dateTimeTab} ${activeTab === 'date' ? styles.activeTab : ''}`}
									onClick={() => switchTab('date')}
									aria-label={`Datepicker switch to date`}
								>
									<CalendarMonthIcon style={{ color: 'var(--brand-navy-default)' }} />
								</button>
								<button
									className={`${styles.dateTimeTab} ${activeTab === 'time' ? styles.activeTab : ''}`}
									onClick={() => switchTab('time')}
									aria-label={`Datepicker switch to time`}
								>
									<AccessTimeIcon style={{ color: 'var(--brand-navy-default)' }} />
								</button>
							</div>}
							{!timeOnly && !timeRange && activeTab === 'date' && <>
								<div className={styles.monthSelector} ref={headerRef}>
									<button {...subtractOffset({ months: 1 })} aria-label={`Datepicker Back 1 month`}>
										<ArrowBack className={styles.arrowIcons} />
									</button>
									<div style={{ flex: 1 }} />
									<button className={`${styles.yearMonthSelector} ${showYearPanel && styles.noSelectorClick}`} onClick={toggleDatePanelDisplay} aria-label={`Datepicker Toggle Selector`}>
										<Text color={'var(--brand-navy-default)'} weight={TEXT_WEIGHT.BOLD}>
											{!showMonthPanel && !showYearPanel && <>{month} {year}</>}
											{showMonthPanel && year}
											{showYearPanel && getSelectableYearRange()}
										</Text>
									</button>
									<div style={{ flex: 1 }} />
									{!dateRange && <button {...addOffset({ months: 1 })} aria-label={'Datepicker Forward 1 month'}>
										<ArrowForward className={styles.arrowIcons} />
									</button>}
								</div>
								{!showMonthPanel && !showYearPanel && <div className={styles.weekDays}>
									{weekDays.map((day:string, i:number) => {
										return <Caption key={`${month}-${day}-${i}`} text={day} />
									})}
								</div>}
							</>}
							{((!dateOnly && activeTab === 'time') || timeRange) && <div className={styles.timeHeader} style={{ marginTop: timeOnly ? '8px' : '0px' }}>
								<Caption text={'HOUR'} />
								<Caption text={'MINUTES'} />
							</div>}
						</header>
						{!timeOnly && !timeRange && activeTab === 'date' && !showMonthPanel && !showYearPanel && <div className={styles.calendar} ref={calRef}>
							{days.map((day:DPDay) => {
								return <button
									key={day.$date.toDateString()}
									className={buildDayClasses(day)}
									onClick={() => { updateDates(day.$date) }}
									disabled={day.disabled}
									aria-label={'Datepicker select Date'}
								>
									{day.day}
								</button>
							})}
						</div>}
						{!timeOnly && !timeRange && activeTab === 'date' && showMonthPanel && <div className={styles.monthYearBlocks}>
							{months.map((month, i) => {
								return <button onClick={() => setNewMonth(month.month)} className={`${styles.monthYearBlock} ${month.now && styles.activeMonthYear}`} key={i} aria-label={'Datepicker select month'}>
									<Text>{month.month}</Text>
								</button>
							})}
						</div>}
						{!timeOnly && !timeRange && activeTab === 'date' && showYearPanel && <div className={styles.monthYearBlocks}>
							{years.map((year, i) => {
								return <button onClick={() => setNewYear(year.year)} className={`${styles.monthYearBlock} ${years[i].now && styles.activeMonthYear}`} key={i} aria-label={'Datepicker select year'}>
									<Text>{year.year}</Text>
								</button>
							})}
						</div>}
						{((!dateOnly && activeTab === 'time') || timeRange) && <div className={styles.timeContainer}>
							<div className={styles.timePanel} style={{ height: `${(calHeight || 250) - 20}px` }} ref={hourContainerRef}>
								{hours.map((hour) => {
									return <span key={hour}>
										<button
											onClick={() => updateTime(`${hour}`, true)}
											className={`${styles.timeButton} ${isHourMin('hour', hour, fieldFocus.current) ? styles.activeTime : ''}`}
											ref={isHourMin('hour', hour, fieldFocus.current) ? hourRef : undefined}
											aria-label={`Datepicker Hour ${hour} button`}
										>
											{withLeading0(hour)}
										</button>
									</span>
								})}
							</div>
							<div className={styles.timePanel} style={{ height: `${(calHeight || 250) - 20}px` }} ref={minContainerRef}>
								{mins.map((min) => {
									return <span key={min}>
										<button
											onClick={() => updateTime(`${min}`, false)}
											className={`${styles.timeButton} ${isHourMin('min', min, fieldFocus.current) ? styles.activeTime : ''}`}
											key={min}
											ref={isHourMin('min', min, fieldFocus.current) ? minRef : undefined}
											aria-label={`Datepicker Minute ${min} button`}
										>
											{withLeading0(min)}
										</button>
									</span>
								})}
							</div>
						</div>}
					</div>
					{dateRange && <Spacer size={10} dir={SPACER_DIRECTION.VERTICAL} divider />}
					{dateRange && <div>
						<header className={styles.calHeader}>
							<div className={styles.monthSelector} ref={headerRef}>
								<div style={{ flex: 1 }} />
								<button className={`${styles.yearMonthSelector} ${showYearPanel && styles.noSelectorClick}`} onClick={toggleDatePanelDisplay} aria-label={'Datepicker toggle year'}>
									<Text color={'var(--brand-navy-default)'} weight={TEXT_WEIGHT.BOLD}>
										{!showMonthPanel && !showYearPanel && <>{month2} {year2}</>}
										{showMonthPanel && year2}
										{showYearPanel && getSelectableYearRange()}
									</Text>
								</button>
								<div style={{ flex: 1 }} />
								<button {...addOffset({ months: 1 })} aria-label={'Datepicker forward 1 month'}>
									<ArrowForward className={styles.arrowIcons} />
								</button>
							</div>
							{!showMonthPanel && !showYearPanel && <div className={styles.weekDays}>
								{weekDays.map((day:string, i:number) => {
									return <Caption key={`${month}-${day}-${i}`} text={day} />
								})}
							</div>}
						</header>
						{!timeOnly && !timeRange && activeTab === 'date' && !showMonthPanel && !showYearPanel && <div className={styles.calendar} ref={calRef}>
							{days2.map((day:DPDay) => {
								return <button
									key={day.$date.toDateString()}
									className={buildDayClasses(day)}
									onClick={() => { updateDates(day.$date) }}
									disabled={day.disabled}
									aria-label={`Datepicker Day ${day.day} button`}
								>
									{day.day}
								</button>
							})}
						</div>}
						{!timeOnly && !timeRange && activeTab === 'date' && showMonthPanel && <div className={styles.monthYearBlocks}>
							{months.map((month, i) => {
								return <button onClick={() => setNewMonth(month.month)} className={`${styles.monthYearBlock} ${month.now && styles.activeMonthYear}`} key={i} aria-label={`Datepicker Month ${month.month} button`}>
									<Text>{month.month}</Text>
								</button>
							})}
						</div>}
						{!timeOnly && !timeRange && activeTab === 'date' && showYearPanel && <div className={styles.monthYearBlocks}>
							{years.map((year, i) => {
								return <button onClick={() => setNewYear(year.year)} className={`${styles.monthYearBlock} ${years[i].now && styles.activeMonthYear}`} key={i} aria-label={`Datepicker Year ${year.year} button`}>
									<Text>{year.year}</Text>
								</button>
							})}
						</div>}
					</div>}
				</div>
				{dateRange && <div className={styles.rangeClearContainer}>
					<button aria-label={'Datepicker Clear'}>Clear</button>
				</div>}
			</div>, document.body)}
		</div>
	);
});

export const Datetimepicker = forwardRef((props:DatepickerProps, _ref) => {
	return <CoreDatepicker { ...props } ref={_ref} />
});

export const Datepicker = forwardRef((props:DatepickerProps, _ref) => {
	return <CoreDatepicker { ...props } ref={_ref} dateOnly />
});

export const Timepicker = forwardRef((props:DatepickerProps, _ref) => {
	return <CoreDatepicker { ...props } ref={_ref} timeOnly />
});
