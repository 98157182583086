export enum BUTTON_TYPES {
	PRIMARY = 'PRIMARY',
	SECONDARY = 'SECONDARY',
	TERTIARY = 'TERTIARY',
	POSITIVE = 'POSITIVE',
	NEGATIVE = 'NEGATIVE',
	ACTION = 'ACTION'
}

export enum BUTTON_SIZE {
	LARGE = 'LARGE',
	SMALL = 'SMALL'
}

export enum BUTTON_ICON_POS {
	LEFT = 'LEFT',
	RIGHT = 'RIGHT',
	TOP = 'TOP',
	BOTTOM = 'BOTTOM'
}

export enum INPUT_TYPE {
	TEXT = 'TEXT',
	NUMBER = 'NUMBER',
	EMAIL = 'EMAIL',
	PHONE = 'PHONE',
	PASSWORD = 'PASSWORD'
}

export enum SPACER_DIRECTION {
	HORIZONTAL = 'HORIZONTAL',
	VERTICAL = 'VERTICAL'
}

export enum CHIP_TYPE {
	INFO = 'INFO',
	WARN = 'WARN',
	ERROR = 'ERROR',
	SUCCESS = 'SUCCESS',
	NEUTRAL = 'NEUTRAL',
	NEW = 'NEW',
	SECONDARY = 'SECONDARY'
}

export enum BUTTON_ACTION_TYPE {
	BUTTON = 'button',
	SUBMIT = 'submit',
	RESET = 'reset'
}

export enum CHIP_ICON_POS {
	LEFT = 'LEFT',
	RIGHT = 'RIGHT'
}

export enum INPUT_ICON_POS {
	LEFT = 'LEFT',
	RIGHT = 'RIGHT'
}

export enum TEMP_NAMES {
	AMBIENT = 'Ambient',
	CHILLED = 'Chilled',
	FROZEN = 'Frozen'
}

export enum SELECT_ICON_POS {
	LEFT = 'LEFT',
	RIGHT = 'RIGHT'
}

export enum TEXT_WEIGHT {
	REGULAR = 'regular',
	LIGHT = 'light',
	BOLD = 'bold'
}

export enum TOOLTIP_POSITION {
	LEFT = 'left',
	RIGHT = 'right',
	TOP = 'top',
	BOTTOM = 'bottom'
}

export enum CARGO_PICKER_TYPE {
	CARGO_DETAILS = 'cargo_details',
	TRUCK_OPTIONS = 'truck_options',
	LOADING_OPTIONS = 'loading_options'
}

export enum CARGO_OPTIONS {
	NOT_DEFINED = 0,
	PALLETS = 1,
	FULL_TRUCK_LOAD = 2,
	VOLUMETRIC = 3,
	LOADING_METRES = 4
}

export enum TRUCK_OPTIONS {
	RIGID = 'Rigid',
	SINGLE = 'Single',
	B_DOUBLE = 'B-Double',
	B_TRIPLE = 'B-Triple',
	B_DOUBLE_HIGH_CUBE = 'B-Double High Cube',
	B_DOUBLE_SUPER_HIGH_CUBE = 'B-Double Super High Cube',
	FT20_CONTAINER = '20ft Container',
	FT40_CONTAINER = '40ft Container',
	FT20_Container_SERVER = 'Container (20FT Coastal)',
	FT40_Container_SERVER = 'Container (40FT Coastal)',
    SUPER_B_DOUBLE = 'Super B-Double',
    FT46_CONTAINER = 'Container (46FT Coastal)',
    FT48_CONTAINER = 'Container (48FT Coastal)',
}

export enum LOADING_OPTIONS {
	BACK = 'From back',
	SIDE = 'From side',
	TOP = 'From top',
	LIFTGATE = 'From liftgate'
}

export enum AVATAR_SIZE {
	LARGE = 'large',
	REGULAR = 'regular',
	SMALL = 'small',
	XSMALL = 'xsmall',
	XXSMALL = 'xxsmall',
	MICRO = 'micro'
}

export enum DATA_UPDATE_MODE {
	SERVER = 'server',
	LOCAL = 'local'
}

export enum DATA_SORT_ORDER {
	DESC = 'desc',
	ASC = 'asc'
}

export enum TABLE_ROW_DENSITY {
	COMPACT = 'compactRow',
	REGULAR = 'regularRow',
	EXPANDED = 'expandedRow'
}

export enum TIMESLOT_OPTIONS {
	NOT_REQUIRED = 'NOT_REQUIRED',
	OFLOAD_MUST_BOOK = 'OFLOAD_MUST_BOOK',
	BOOKED = 'BOOKED'
}

export const getCargoTypeLabel = (cargoType: CARGO_OPTIONS): string => {
	switch (cargoType) {
	case CARGO_OPTIONS.PALLETS:
		return 'AU Standard Pallet';
	case CARGO_OPTIONS.FULL_TRUCK_LOAD:
		return 'Truckload';
	case CARGO_OPTIONS.VOLUMETRIC:
		return 'Other';
	case CARGO_OPTIONS.LOADING_METRES:
		return 'Loading Metres';
	case CARGO_OPTIONS.NOT_DEFINED:
	default:
		return 'Not Defined';
	}
}

export const CARGO_TYPE_ID = {
	Pallets: 1,
	FullTruckload: 2,
	Volumetric: 3,
	LoadingMeters: 4,
	NotDefined: 0,
	Loose: 5,
	Other: 6,
}

export const JOB_STATUS = {
	UNALLOCATED: 0,
	IN_MARKETPLACE: 1,
	PREMIUM_LIST: 2,
	ALLOCATED: 3,
	CANCEL: 4,
}

export const QuoteFilterableStatus = [
	{ value: 'pending', label: 'Pending' },
	{ value: 'priced', label: 'Priced' },
	{ value: 'expired', label: 'Expired' },
	{ value: 'not_quoted', label: 'Not Quoted' }
]

export const TRANSPORT_SHIPMENT_FILTERABLE_STATUS = [
	'Allocated',
	'On The Road',
	'Delivered',
	'Awaiting POD Upload',
	'POD Awaiting Verification',
	'POD Rejected',
	'Shipper Invoice Generated',
	'Awaiting Carrier Invoice',
	'Shipper Invoiced',
	'Carrier Invoiced',
	'Overdue Shipper Payment',
	'Shipper Paid',
	'Carrier Paid',
	'Chasing POD',
	'In Dispute',
	'POD Chased And Abandoned',
	'No POD Available',
	'Shipper Payment Dispute',
	'Carrier Invoice Pending/Chasing',
	'No Carrier Invoice Provided',
];

export const ShipmentFilterableStatus = ['Pending', 'Allocated', 'On The Road', 'Delivered', 'Awaiting', 'Paid'];

export enum TRUCK_SIZE {
    RIGID = 'RIGID',
    SINGLE = 'SINGLE',
    B_DOUBLE = 'B_DOUBLE',
    SKELETON = 'SKELETON',
    SIDELOADER = 'SIDELOADER',
    B_DOUBLE_HIGH_CUBE = 'B_DOUBLE_HIGH_CUBE',
    CONTAINER_20FT_COASTAL = 'CONTAINER_20FT_COASTAL',
    CONTAINER_40FT_COASTAL = 'CONTAINER_40FT_COASTAL',
    CONTAINER_46FT_COASTAL = 'CONTAINER_46FT_COASTAL',
    CONTAINER_48FT_COASTAL = 'CONTAINER_48FT_COASTAL',
    B_TRIPLE = 'B_TRIPLE',
    SUPER_B_DOUBLE = 'SUPER_B_DOUBLE',
}

export enum TRUCK_TYPE {
    ANY = 'ANY',
    TAUTLINER = 'TAUTLINER',
    PANTECH = 'PANTECH',
    FLAT_TOP = 'FLAT_TOP',
    TAUTLINER_STRAIGHT_DECK = 'TAUTLINER_STRAIGHT_DECK',
    TAUTLINER_DROP_DECK = 'TAUTLINER_DROP_DECK',
    TAUTLINER_MEZZANINE_DECK = 'TAUTLINER_MEZZANINE_DECK',
    SIDELOADER = 'SIDELOADER',
    SKELETON = 'SKELETON_TRAILER',
    OPEN_TOP = 'OPEN_TOP',
    UTE = 'UTE',
    TIPPER = 'TIPPER',
	SUPER_B_DOUBLE = 'SUPER_B_DOUBLE',
	B_TRIPLE = 'B_TRIPLE'
}

export enum FREIGHT_MODE {
    ROAD = 'Road',
    SEA = 'Sea',
    AIR = 'Air',
    RAIL = 'Rail',
    OTHER = 'Other'
}

export enum RATE_CARD_MODIFIERS {
	TRUCK1 = 'Tautliner',
	TRUCK2 = 'Pantech',
	TRUCK3 = 'Flat Top',
	TRUCK4 = 'Tautliner Straight Deck',
	TRUCK5 = 'Tautliner Drop Deck',
	TRUCK6 = 'Tautliner Mezzanine Deck',
	TRUCK7 = 'Sideloader',
	TRUCK8 = 'Skeleton Trailer',
	TRUCK9 = 'Open Top',
	TRUCK10 = 'Tipper',
}

export const COUNTRY_CODES = {
	AF: ['Afghanistan', 'AF', '93'],
	AL: ['Albania', 'AL', '355'],
	DZ: ['Algeria', 'DZ', '213'],
	AD: ['Andorra', 'AD', '376'],
	AO: ['Angola', 'AO', '244'],
	AG: ['Antigua and Barbuda', 'AG', '1268'],
	AR: ['Argentina', 'AR', '54', '(..) ........', 0],
	AM: ['Armenia', 'AM', '374', '.. ......'],
	AW: ['Aruba', 'AW', '297'],
	AU: ['Australia', 'AU', '61', '(..) .... ....'],
	AS: ['American Samoa', 'AS', '1'],
	AT: ['Austria', 'AT', '43'],
	AZ: ['Azerbaijan', 'AZ', '994', '(..) ... .. ..'],
	BS: ['Bahamas', 'BS', '1242'],
	BH: ['Bahrain', 'BH', '973'],
	BD: ['Bangladesh', 'BD', '880'],
	BB: ['Barbados', 'BB', '1246'],
	BY: ['Belarus', 'BY', '375', '(..) ... .. ..'],
	BE: ['Belgium', 'BE', '32', '... .. .. ..'],
	BZ: ['Belize', 'BZ', '501'],
	BJ: ['Benin', 'BJ', '229'],
	BT: ['Bhutan', 'BT', '975'],
	BO: ['Bolivia', 'BO', '591'],
	BA: ['Bosnia and Herzegovina', 'BA', '387'],
	BW: ['Botswana', 'BW', '267'],
	BR: ['Brazil', 'BR', '55', '(..) .........'],
	IO: ['British Indian Ocean Territory', 'IO', '246'],
	BN: ['Brunei', 'BN', '673'],
	BG: ['Bulgaria', 'BG', '359'],
	BF: ['Burkina Faso', 'BF', '226'],
	BI: ['Burundi', 'BI', '257'],
	KH: ['Cambodia', 'KH', '855'],
	CM: ['Cameroon', 'CM', '237'],
	CA: ['Canada', 'CA', '1', '(...) ...-....'],
	CV: ['Cape Verde', 'CV', '238'],
	BQ: ['Caribbean Netherlands', 'BQ', '599', '', 1],
	CF: ['Central African Republic', 'CF', '236'],
	TD: ['Chad', 'TD', '235'],
	CL: ['Chile', 'CL', '56'],
	CN: ['China', 'CN', '86', '..-.........'],
	CO: ['Colombia', 'CO', '57', '... ... ....'],
	KM: ['Comoros', 'KM', '269'],
	CD: ['Congo', 'CD', '243'],
	CG: ['Congo', 'CG', '242'],
	CR: ['Costa Rica', 'CR', '506', '....-....'],
	CI: ['Côte d’Ivoire', 'CI', '225', '.. .. .. ..'],
	HR: ['Croatia', 'HR', '385'],
	CU: ['Cuba', 'CU', '53'],
	CW: ['Curaçao', 'CW', '599', '', 0],
	CY: ['Cyprus', 'CY', '357', '.. ......'],
	CZ: ['Czech Republic', 'CZ', '420', '... ... ...'],
	DK: ['Denmark', 'DK', '45', '.. .. .. ..'],
	DJ: ['Djibouti', 'DJ', '253'],
	DM: ['Dominica', 'DM', '1767'],
	DO: ['Dominican Republic', 'DO', '1', ''],
	EC: ['Ecuador', 'EC', '593'],
	EG: ['Egypt', 'EG', '20'],
	SV: ['El Salvador', 'SV', '503', '....-....'],
	GQ: ['Equatorial Guinea', 'GQ', '240'],
	ER: ['Eritrea', 'ER', '291'],
	EE: ['Estonia', 'EE', '372', '.... ......'],
	ET: ['Ethiopia', 'ET', '251'],
	FJ: ['Fiji', 'FJ', '679'],
	FI: ['Finland', 'FI', '358', '.. ... .. ..'],
	FR: ['France', 'FR', '33', '. .. .. .. ..'],
	GF: ['French Guiana', 'GF', '594'],
	PF: ['French Polynesia', 'PF', '689'],
	GA: ['Gabon', 'GA', '241'],
	GM: ['Gambia', 'GM', '220'],
	GE: ['Georgia', 'GE', '995'],
	DE: ['Germany', 'DE', '49', '.... ........'],
	GH: ['Ghana', 'GH', '233'],
	GR: ['Greece', 'GR', '30'],
	GD: ['Grenada', 'GD', '1473'],
	GP: ['Guadeloupe', 'GP', '590', '', 0],
	GU: ['Guam', 'GU', '1671'],
	GT: ['Guatemala', 'GT', '502', '....-....'],
	GN: ['Guinea', 'GN', '224'],
	GW: ['Guinea-Bissau', 'GW', '245'],
	GY: ['Guyana', 'GY', '592'],
	HT: ['Haiti', 'HT', '509', '....-....'],
	HN: ['Honduras', 'HN', '504'],
	HK: ['Hong Kong', 'HK', '852', '.... ....'],
	HU: ['Hungary', 'HU', '36'],
	IS: ['Iceland', 'IS', '354', '... ....'],
	IN: ['India', 'IN', '91', '.....-.....'],
	ID: ['Indonesia', 'ID', '62'],
	IR: ['Iran', 'IR', '98', '... ... ....'],
	IQ: ['Iraq', 'IQ', '964'],
	IE: ['Ireland', 'IE', '353', '.. .......'],
	IL: ['Israel', 'IL', '972', '... ... ....'],
	IT: ['Italy', 'IT', '39', '... .......', 0],
	JM: ['Jamaica', 'JM', '1876'],
	JP: ['Japan', 'JP', '81', '.. .... ....'],
	JO: ['Jordan', 'JO', '962'],
	KZ: ['Kazakhstan', 'KZ', '7', '... ...-..-..'],
	KE: ['Kenya', 'KE', '254'],
	KI: ['Kiribati', 'KI', '686'],
	XK: ['Kosovo', 'XK', '383'],
	KW: ['Kuwait', 'KW', '965'],
	KG: ['Kyrgyzstan', 'KG', '996', '... ... ...'],
	LA: ['Laos', 'LA', '856'],
	LV: ['Latvia', 'LV', '371', '.. ... ...'],
	LB: ['Lebanon', 'LB', '961'],
	LS: ['Lesotho', 'LS', '266'],
	LR: ['Liberia', 'LR', '231'],
	LY: ['Libya', 'LY', '218'],
	LI: ['Liechtenstein', 'LI', '423'],
	LT: ['Lithuania', 'LT', '370'],
	LU: ['Luxembourg', 'LU', '352'],
	MO: ['Macau', 'MO', '853'],
	MK: ['Macedonia', 'MK', '389'],
	MG: ['Madagascar', 'MG', '261'],
	MW: ['Malawi', 'MW', '265'],
	MY: ['Malaysia', 'MY', '60', '..-....-....'],
	MV: ['Maldives', 'MV', '960'],
	ML: ['Mali', 'ML', '223'],
	MT: ['Malta', 'MT', '356'],
	MH: ['Marshall Islands', 'MH', '692'],
	MQ: ['Martinique', 'MQ', '596'],
	MR: ['Mauritania', 'MR', '222'],
	MU: ['Mauritius', 'MU', '230'],
	MX: ['Mexico', 'MX', '52', '... ... ....'],
	FM: ['Micronesia', 'FM', '691'],
	MD: ['Moldova', 'MD', '373', '(..) ..-..-..'],
	MC: ['Monaco', 'MC', '377'],
	MN: ['Mongolia', 'MN', '976'],
	ME: ['Montenegro', 'ME', '382'],
	MA: ['Morocco', 'MA', '212'],
	MZ: ['Mozambique', 'MZ', '258'],
	MM: ['Myanmar', 'MM', '95'],
	NA: ['Namibia', 'NA', '264'],
	NR: ['Nauru', 'NR', '674'],
	NP: ['Nepal', 'NP', '977'],
	NL: ['Netherlands', 'NL', '31', '.. ........'],
	NC: ['New Caledonia', 'NC', '687'],
	NZ: ['New Zealand', 'NZ', '64', '...-...-....'],
	NI: ['Nicaragua', 'NI', '505'],
	NE: ['Niger', 'NE', '227'],
	NG: ['Nigeria', 'NG', '234'],
	KP: ['North Korea', 'KP', '850'],
	NO: ['Norway', 'NO', '47', '... .. ...'],
	OM: ['Oman', 'OM', '968'],
	PK: ['Pakistan', 'PK', '92', '...-.......'],
	PW: ['Palau', 'PW', '680'],
	PS: ['Palestine', 'PS', '970'],
	PA: ['Panama', 'PA', '507'],
	PG: ['Papua New Guinea', 'PG', '675'],
	PY: ['Paraguay', 'PY', '595'],
	PE: ['Peru', 'PE', '51'],
	PH: ['Philippines', 'PH', '63', '.... .......'],
	PL: ['Poland', 'PL', '48', '...-...-...'],
	PT: ['Portugal', 'PT', '351'],
	PR: ['Puerto Rico', 'PR', '1', ''],
	QA: ['Qatar', 'QA', '974'],
	RE: ['Réunion', 'RE', '262'],
	RO: ['Romania', 'RO', '40'],
	RU: ['Russia', 'RU', '7', '(...) ...-..-..', 0],
	RW: ['Rwanda', 'RW', '250'],
	KN: ['Saint Kitts and Nevis', 'KN', '1869'],
	LC: ['Saint Lucia', 'LC', '1758'],
	VC: ['Saint Vincent and the Grenadines', 'VC', '1784'],
	WS: ['Samoa', 'WS', '685'],
	SM: ['San Marino', 'SM', '378'],
	ST: ['São Tomé and Príncipe', 'ST', '239'],
	SA: ['Saudi Arabia', 'SA', '966'],
	SN: ['Senegal', 'SN', '221'],
	RS: ['Serbia', 'RS', '381'],
	SC: ['Seychelles', 'SC', '248'],
	SL: ['Sierra Leone', 'SL', '232'],
	SG: ['Singapore', 'SG', '65', '....-....'],
	SK: ['Slovakia', 'SK', '421'],
	SI: ['Slovenia', 'SI', '386'],
	SB: ['Solomon Islands', 'SB', '677'],
	SO: ['Somalia', 'SO', '252'],
	ZA: ['South Africa', 'ZA', '27'],
	KR: ['South Korea', 'KR', '82', '... .... ....'],
	SS: ['South Sudan', 'SS', '211'],
	ES: ['Spain', 'ES', '34', '... ... ...'],
	LK: ['Sri Lanka', 'LK', '94'],
	SD: ['Sudan', 'SD', '249'],
	SR: ['Suriname', 'SR', '597'],
	SZ: ['Swaziland', 'SZ', '268'],
	SE: ['Sweden', 'SE', '46', '(...) ...-...'],
	CH: ['Switzerland', 'CH', '41', '.. ... .. ..'],
	SY: ['Syria', 'SY', '963'],
	TW: ['Taiwan', 'TW', '886'],
	TJ: ['Tajikistan', 'TJ', '992'],
	TZ: ['Tanzania', 'TZ', '255'],
	TH: ['Thailand', 'TH', '66'],
	TL: ['Timor-Leste', 'TL', '670'],
	TG: ['Togo', 'TG', '228'],
	TO: ['Tonga', 'TO', '676'],
	TT: ['Trinidad and Tobago', 'TT', '1868'],
	TN: ['Tunisia', 'TN', '216'],
	TR: ['Turkey', 'TR', '90', '... ... .. ..'],
	TM: ['Turkmenistan', 'TM', '993'],
	TV: ['Tuvalu', 'TV', '688'],
	UG: ['Uganda', 'UG', '256'],
	UA: ['Ukraine', 'UA', '380', '(..) ... .. ..'],
	AE: ['United Arab Emirates', 'AE', '971'],
	GB: ['United Kingdom', 'GB', '44', '.... ......'],
	US: ['United States', 'US', '1', '(...) ...-....'],
	UY: ['Uruguay', 'UY', '598'],
	UZ: ['Uzbekistan', 'UZ', '998', '.. ... .. ..'],
	VU: ['Vanuatu', 'VU', '678'],
	VA: ['Vatican City', 'VA', '39', '.. .... ....', 1],
	VE: ['Venezuela', 'VE', '58'],
	VN: ['Vietnam', 'VN', '84'],
	YE: ['Yemen', 'YE', '967'],
	ZM: ['Zambia', 'ZM', '260'],
	ZW: ['Zimbabwe', 'ZW', '263'],
};

export const DAYS = [
	{
		value: 0,
		label: 'Sunday',
	},
	{
		value: 1,
		label: 'Monday',
	},
	{
		value: 2,
		label: 'Tuesday',
	},
	{
		value: 3,
		label: 'Wednesday',
	},
	{
		value: 4,
		label: 'Thursday',
	},
	{
		value: 5,
		label: 'Friday',
	},
	{
		value: 6,
		label: 'Saturday',
	},
];
export const MONTH_NAMES = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December'
]