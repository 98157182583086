import { PropsWithChildren } from 'react';
import styles from './Grid.module.scss';
import { TObject } from '@types';
import { Item } from './GridItem';

type TProps = {
	container?: boolean;
	item?: boolean;
	columns?: number;
	minWidth?: string;
	maxWidth?: string;
	gap?: string;
	columnGap?: string;
	rowGap?: string;
	rowHeight?: string;
	columnTemplate?: string;
}

const GridContainer = (props:PropsWithChildren<TProps>) => {
	const {
		columns = 2,
		minWidth = '200px',
		maxWidth = '1fr',
		gap,
		columnGap = '16px',
		rowGap = '16px',
		rowHeight,
		columnTemplate,
		children
	} = props;

	const setContainerStyles = () => {
		const styles:TObject = {};
		const totalGapWidth = (columns - 1) * parseInt(gap ?? columnGap);
		const repeat = `repeat(auto-fill, minmax( max(${minWidth}, calc((100% - ${totalGapWidth}px) / ${columns})), ${maxWidth}))`;
		const template = columnTemplate?.replace(/{repeat}/g, repeat);
		styles.gridTemplateColumns = template ?? repeat;
		if (gap) {
			styles.gap = gap;
		} else {
			styles.rowGap = rowGap;
			styles.columnGap = columnGap;
		}
		if (rowHeight) {
			styles.gridAutoRows = rowHeight;
		}
		return styles;
	}

	return (
		<div data-testid={'@Ofload-Grid'} className={styles.container} style={setContainerStyles()}>
			{children}
		</div>
	);
}

GridContainer.Item = Item;

export const Grid = GridContainer;
