import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { RecoilRoot } from 'recoil';
import Injector from './App/Injector';

const root = ReactDOM.createRoot(document.getElementById('root') as Element);
root.render(
	<React.StrictMode>
		<RecoilRoot>
			<Injector />
		</RecoilRoot>
	</React.StrictMode>
);
