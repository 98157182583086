import { useState, useEffect, PropsWithChildren } from 'react';
import styles from './Text.module.scss';
import { TEXT_WEIGHT } from '@types';

type TextProps = {
	'data-testid'?: string;
	type?: string;
	text?: string;
	weight?: TEXT_WEIGHT;
	color?: string;
	target?: string;
	required?: boolean;
	disabled?: boolean;
}

export const Text = (props: PropsWithChildren<TextProps>) => {
	const {
		'data-testid': dataTestId,
		type = 'p',
		text = '',
		children,
		weight,
		color = null,
		required,
		disabled
	} = props;
	const [Weight, setWeight] = useState(weight || 'regular');
	const setStyle = () => {
		if (color === null) {
			return {}
		}
		return { color: color }
	}
	const isRequired = () => {
		return required && <span data-testid={'@Ofload-Text-Required'} className={styles.required}> *</span>
	}
	useEffect(() => {
		if (weight) {
			setWeight(weight);
		} else {
			switch (type) {
			case 'h1':
			case 'h2':
			case 'label':
				setWeight('bold');
				break;
			case 'h3':
				setWeight('semiBold');
				break;
			default:
				setWeight('regular');
			}
		}
	}, [type, weight]);
	return (
		<>
			{type === 'h0' && <h1 data-testid={dataTestId} className={`${styles.h0} ${styles[Weight]} ${disabled ? styles.disabled : ''}`} style={setStyle()}>{children ?? text}{isRequired()}</h1>}
			{type === 'h1' && <h1 data-testid={dataTestId} className={`${styles.h1} ${styles[Weight]} ${disabled ? styles.disabled : ''}`} style={setStyle()}>{children ?? text}{isRequired()}</h1>}
			{type === 'h2' && <h2 data-testid={dataTestId} className={`${styles.h2} ${styles[Weight]} ${disabled ? styles.disabled : ''}`} style={setStyle()}>{children ?? text}{isRequired()}</h2>}
			{type === 'h3' && <h3 data-testid={dataTestId} className={`${styles.h3} ${styles[Weight]} ${disabled ? styles.disabled : ''}`} style={setStyle()}>{children ?? text}{isRequired()}</h3>}
			{type === 'label' && <label data-testid={dataTestId} className={`${styles.label} ${styles[Weight]} ${disabled ? styles.disabled : ''}`} style={setStyle()}>{children ?? text}{isRequired()}</label>}
			{type === 'p' && <p data-testid={dataTestId} className={`${styles.text} ${styles[Weight]} ${disabled ? styles.disabled : ''}`} style={setStyle()}>{children ?? text}{isRequired()}</p>}
			{type === 'caption' && <p data-testid={dataTestId} className={`${styles.caption} ${styles[Weight]} ${disabled ? styles.disabled : ''}`} style={setStyle()}>{children ?? text}{isRequired()}</p>}
			{type === 'small' && <p data-testid={dataTestId} className={`${styles.small} ${styles[Weight]} ${disabled ? styles.disabled : ''}`} style={setStyle()}>{children ?? text}{isRequired()}</p>}
		</>
	);
}

export const H0 = (props: PropsWithChildren<TextProps>) => {
	return <Text type={'h0'} {...props} />
}

export const H1 = (props: PropsWithChildren<TextProps>) => {
	return <Text type={'h1'} {...props} />
}

export const H2 = (props: PropsWithChildren<TextProps>) => {
	return <Text type={'h2'} {...props} />
}

export const H3 = (props: PropsWithChildren<TextProps>) => {
	return <Text type={'h3'} {...props} />
}

export const Label = (props: PropsWithChildren<TextProps>) => {
	return <Text type={'label'} {...props} />
}

export const Caption = (props: PropsWithChildren<TextProps>) => {
	return <Text type={'caption'} {...props} />
}

export const SmallText = (props: PropsWithChildren<TextProps>) => {
	return <Text type={'small'} {...props} />
}
