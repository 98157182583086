type TProps = {
	width?: number;
	height?: number;
	borderColor?: string;
	fillColor?: string;
	className?: string;
}

export const CheckBoxDisabledUnchecked = (props:TProps) => {
	const {
		width = 24,
		height = 24,
		borderColor = 'var(--neutral-03)',
		fillColor = 'var(--neutral-02)',
		className
	} = props;
	return (
		<svg role={'img'} className={className} width={width} height={height} focusable='false' viewBox='0 0 24 24'>
			<path fill={fillColor} d='M 4 4 L 4 20 L 20 20 L 20 4 L 4 4' />
			<path fill={borderColor} d='M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z'></path>
		</svg>
	);
}
