import formatISO from 'date-fns/formatISO';
import parseISO from 'date-fns/parseISO';
import formatFns from 'date-fns/format';
import parseFns from 'date-fns/parse';
import add from 'date-fns/add';

type ObjectType = {
	[key:string]: string;
}

export const DATE_FORMATS:ObjectType = {
	API_DATE_FORMAT: 'yyyy-MM-dd',
	SHORT_DATE: 'dd/MM/yyyy',
	DATE_WITH_SHORT_MONTH_NAME: 'dd MMM yyyy',
	DATE_WITH_FULL_MONTH_NAME: 'dd MMM yyyy',
	ISO: 'ISO',
	US_DATE_WITH_SHORT_MONTH_NAME: 'MMM dd, yyyy',
	US_TEXT: 'MMM, do yy'
}

const formatFromName = (name:string) => {
	return DATE_FORMATS[name] || name
}

/**
 * @param {string} [dateString]
 * @param { keyof DATE_FORMATS } [format]
 */
export const parseDateString = (dateString: string, format='API_DATE_FORMAT') => {
	const formatPattern = formatFromName(format);
	if (!dateString) {
		return new Date(NaN);
	}

	if (format === DATE_FORMATS.ISO) {
		return parseISO(dateString);
	}

	return parseFns(dateString, formatPattern, new Date());
}

/**
 * @param {Date} date
 * @param {keyof DATE_FORMATS} [format]
 */
export const formatDate = (date: Date, format= 'SHORT_DATE') => {
	const formatPattern = formatFromName(format);

	if (format === DATE_FORMATS.ISO) {
		return formatISO(date);
	}

	return formatFns(date, formatPattern);
}

export const convertFromAus = (date: string) => {
	if (date && typeof date === 'string' && date.indexOf('/') >= 0) {
		const parts = date.split('/').reverse();
		return parts.join('-');
	}
	return date;
}

export const getTime = (date: Date, as24 = true, returnParts = false) => {
	const theDate = new Date(date);
	const Hour = theDate.getHours();
	const Hour12 = Hour > 12 ? Hour - 12 : Hour;
	const Min = theDate.getMinutes();
	const Period = Hour < 12 ? 'am' : 'pm';
	if (returnParts) {
		return {
			hour: Hour,
			min: Min,
			period: Period
		}
	}
	return `${as24 ? Hour : Hour12}:${Min}${as24 ? '' : ` ${Period}`}`;
}

export const formatTimeFromString = (inputTime:string) => {
	const time = `${inputTime}`; // --> format to string... just in case...
	const [hour, min] = time.split(':');
	const Hour12 = parseInt(hour) > 12 ? parseInt(hour) - 12 : parseInt(hour);
	const Period = parseInt(hour) < 12 ? 'am' : 'pm';
	return `${Hour12}:${min}${Period}`;
}

export const getDateFromTime = (date:Date, time:string) => {
	if (date && time) {
		const [hour, min] = time.split(':');
		const year = date.getFullYear();
		const month = date.getMonth();
		const day = date.getDate();
		return new Date(year, month, day, parseInt(hour), parseInt(min));
	}
	return null;
}

export const convertAusDate = (dateString:string) => {
	const dateParts = dateString.split('/');
	if (dateParts.length < 3) return null;
	return new Date(parseInt(dateParts[2]), parseInt(dateParts[1]) - 1, parseInt(dateParts[0]));
}

/**
 * @param {string} [dateString]
 * @param {keyof DATE_FORMATS} [outputFormat]
 * @param {keyof DATE_FORMATS} [inputFormat]
 * @param {string} [invalidDateString]
 */
export const parseAndFormatDate = (dateString: string, outputFormat='SHORT_DATE', inputFormat='API_DATE_FORMAT', invalidDateString='') => {
	const date = parseDateString(dateString, inputFormat);
	if (isNaN(date.getTime())) {
		return invalidDateString;
	}

	return formatDate(date, outputFormat)
}

export const getDayName = (date:string) => {
	const theDate = new Date(date);
	const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
	const day = theDate.getDay();
	return days[day];
}

export const formatDateOz = (dateString: Date, format = 'Do MMM YYYY') => {
	return formatDate(dateString, format);
}

export const formatTime = (value: Date, format = 'h:mma') => {
	return formatDate(value, format);
}

export const addToTime = (time: string, hours: number = 0, minutes: number = 0, seconds: number = 0) => {
	const [hour, min] = time.split(':');
	const now = new Date();
	const date = new Date(now.getFullYear(), now.getMonth() -1, now.getDate(), parseInt(hour), parseInt(min));
	const result = add(date, {
		hours,
		minutes,
		seconds
	});
	const addLeadingZero = (n:number) => n <= 9 ? `0${n}` : `${n}`;
	return `${addLeadingZero(result.getHours())}:${addLeadingZero(result.getMinutes())}:${addLeadingZero(result.getSeconds())}`;
}
