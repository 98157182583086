import { MouseEvent, ElementType } from 'react';
import styles from './Button.module.scss';
import { Loader } from '@components';
import { BUTTON_ACTION_TYPE, BUTTON_ICON_POS, BUTTON_SIZE, BUTTON_TYPES } from '@types';

export type ButtonProps = {
	id?: string;
	'data-testid'?: string;
	text: string | JSX.Element;
	onClick: (e:MouseEvent<HTMLButtonElement>) => any;
	type?: BUTTON_TYPES;
	size?: BUTTON_SIZE;
	disabled?: boolean;
	Icon?: ElementType;
	iconPos?: BUTTON_ICON_POS;
	loading?: boolean;
	loaderSize?: number;
	loaderThickness?: number;
	loaderColor?: string;
	loaderTrayColor?: string;
	actionType?: BUTTON_ACTION_TYPE;
	stretch?: boolean;
	inActive?: boolean;
	iconDisableColor?: string;
	iconActiveColor?: string;
	iconOnly?: boolean;
}

export const Button = (props:ButtonProps) => {
	const {
		id,
		'data-testid': dataTestId,
		text,
		onClick,
		type = BUTTON_TYPES.PRIMARY,
		size = BUTTON_SIZE.LARGE,
		disabled = false,
		Icon = null,
		iconPos = BUTTON_ICON_POS.LEFT,
		loading = false,
		loaderSize,
		loaderThickness,
		loaderColor = 'var(--func-blue-default)',
		loaderTrayColor = 'var(--neutral-01)',
		iconActiveColor= '',
		iconDisableColor = '',
		actionType = BUTTON_ACTION_TYPE.BUTTON,
		stretch = false,
		inActive = false,
		iconOnly = false
	} = props;
	const addBtnType = () => {
		switch (type) {
		case BUTTON_TYPES.SECONDARY:
			return styles.secondary;
		case BUTTON_TYPES.TERTIARY:
		case BUTTON_TYPES.ACTION:
			return styles.tertiary;
		case BUTTON_TYPES.POSITIVE:
			return styles.positive;
		case BUTTON_TYPES.NEGATIVE:
			return styles.negative;
		default:
			return styles.primary;
		}
	}
	const addBtnDisabled = () => {
		switch (type) {
		case BUTTON_TYPES.SECONDARY:
			return styles.disabled;
		case BUTTON_TYPES.TERTIARY:
		case BUTTON_TYPES.ACTION:
			return styles.tertiaryDisabled;
		case BUTTON_TYPES.POSITIVE:
			return styles.positiveDisabled;
		case BUTTON_TYPES.NEGATIVE:
			return styles.negativeDisabled;
		default:
			return styles.disabled;
		}
	}
	const addBtnSize = () => {
		switch (size) {
		case BUTTON_SIZE.SMALL:
			return styles.small;
		default:
			return styles.large;
		}
	}
	const triggerOnClick = (e:MouseEvent<HTMLButtonElement>) => {
		if (!disabled && !loading) onClick(e);
	}

	const iconStyle = disabled && iconDisableColor
		? { color: iconDisableColor }
		: iconActiveColor
			? { color: iconActiveColor }
			: {};


	return (
		<button id={id} data-testid={dataTestId} type={actionType} onClick={triggerOnClick} disabled={disabled} className={`${styles.container} ${addBtnType()} ${addBtnSize()} ${disabled ? addBtnDisabled() : ''} ${stretch ? styles.stretch : ''} ${inActive ? styles.inActive : ''} ${iconOnly && styles.iconButton}`} aria-label={`${text} button`}>
			{iconOnly && Icon !== null && <Icon data-testid={'@Ofload-Btn-Icon-Only'} className={styles.iconOnly} style={iconStyle}/>}
			{!iconOnly && <span style={{ opacity: loading ? 0 : 1 }} className={`${styles.inner} ${type === BUTTON_TYPES.ACTION && styles.innerAction}`}>
				{iconPos === BUTTON_ICON_POS.LEFT && Icon !== null && <Icon data-testid={'@Ofload-Btn-Icon-Left'} className={styles.iconLeft} style={iconStyle}/>}
				{iconPos === BUTTON_ICON_POS.TOP && Icon !== null && <Icon data-testid={'@Ofload-Btn-Icon-Top'} className={styles.iconTop} style={iconStyle}/>}
				{text}
				{iconPos === BUTTON_ICON_POS.RIGHT && Icon !== null && <Icon data-testid={'@Ofload-Btn-Icon-Right'} className={styles.iconRight} style={iconStyle}/>}
				{iconPos === BUTTON_ICON_POS.BOTTOM && Icon !== null && <Icon data-testid={'@Ofload-Btn-Icon-Bottom'} className={styles.iconBottom} style={iconStyle}/>}
			</span>}
			{loading && <span data-testid={'@Ofload-Btn-Loader'} style={{ position: 'absolute' }}>
				<Loader size={loaderSize ? loaderSize : size === BUTTON_SIZE.SMALL ? 10 : 15} spinnerColor={loaderColor} trayColor={loaderTrayColor} thickness={loaderThickness ? loaderThickness : size === BUTTON_SIZE.SMALL ? 3 : 5} />
			</span>}
		</button>
	);
}

export const ActionButton = (props: ButtonProps) => {
	const {
		text,
		onClick
	} = props;
	return <Button {...props} type={BUTTON_TYPES.ACTION} text={text} onClick={onClick} iconPos={BUTTON_ICON_POS.TOP} />
}
