import { AJAXError } from '@types';
import { usePostAPI, useGetAPI } from './useAPI';

/*
 * NOTE: Once all FE has been migrated away from EOS
 * monolith, replace /login & /logout with /api/login
 * & /api/logout.
 *
 * Currently these routes are here to maintain the
 * cookies/session info inside EOS. Once all pages
 * are in the new FE and BE is all API routes these
 * will no longer need cookies/session info...
 */

export const useLogin = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = usePostAPI({
		endpoint: '/login',
		domain: 'Inertia'
	});

	return {
		isLoading,
		data: data as { jwt: string, refreshToken: string, redirect: string },
		error: error as AJAXError,
		callAPI
	}
}

export const useLogout = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = usePostAPI({
		endpoint: '/logout',
		domain: 'Inertia'
	});

	const triggerAPI = () => {
		const payload = { refresh_token: localStorage.getItem('refreshToken') }
		callAPI({ payload });
	}

	return {
		isLoadingLogout: isLoading,
		logoutData: data as any,
		logoutError: error,
		callLogoutAPI: triggerAPI
	}
}

export const useIsGuest = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({
		endpoint: '/guest',
		domain: 'Inertia'
	});

	return {
		isLoadingGuest: isLoading,
		guestData: data as any,
		guestError: error,
		callGetGuestAPI: callAPI
	}
}

export const useResetPassword = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = usePostAPI({
		endpoint: '/submitforgot',
		domain: 'EOS',
	});

	return {
		isLoading,
		data: data as { type: string, email: string, message: string },
		error: error as AJAXError,
		callAPI
	}
}
