import { atom } from 'recoil';

/* eslint-disable */
const keys = {
	JWT:			'@Ofload/JWT',
	USERID:			'@Ofload/UserID',
	COMPANYID:		'@Ofload/CompanyID',
	ISADMIN:		'@Ofload/IsAdmin',
	USERNAME:		'@Ofload/UserName',
	COMPANYNAME:	'@Ofload/CompanyName',
  	USER_ROLES: 	'@Ofload/UserRoles',
	WAIT_FOR_GUEST:	'@Ofload/WaitForGuest'
}
/* eslint-enable */

export const JWT = atom<string | null>({
	key: keys.JWT,
	default: null
});

export const UserID = atom<number | string | null>({
	key: keys.USERID,
	default: null
});

export const CompanyID = atom<number | string | null>({
	key: keys.COMPANYID,
	default: null
});

export const IsAdmin = atom<boolean>({
	key: keys.ISADMIN,
	default: false
});

export const UserName = atom<string>({
	key: keys.USERNAME,
	default: ''
});

export const CompanyName = atom<string>({
	key: keys.COMPANYNAME,
	default: ''
});

export const UserRoles = atom<string[]>({
	key: keys.USER_ROLES,
	default: []
});

export const WaitForGuest = atom<boolean>({
	key: keys.WAIT_FOR_GUEST,
	default: true
});
