import React, { ChangeEvent, FocusEvent, ReactNode } from 'react';
import styles from './Checkbox.module.scss';
import { Text, Caption, Spacer } from '@components';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import { VALIDATION_ERROR } from '@types';
import { CheckboxGroup } from './CheckboxGroup';
import { CheckBoxDisabledUnchecked } from './Checkbox.disabled.unchecked';
import { ZodTypeAny } from 'zod';

interface CheckboxProps extends React.ComponentPropsWithoutRef<'input'> {
	name: string;
	label: string | ReactNode;
	value: string;
	onChange: (e:ChangeEvent<HTMLInputElement>) => void;
	checked: boolean;
	indeterminate?: boolean;
	activeColor?: string;
	inActiveColor?: string;
	inActiveBorderColor?: string;
	disabledColor?: string;
	onFocus?: (e:FocusEvent<HTMLInputElement>) => void;
	onBlur?: (e:FocusEvent<HTMLInputElement>) => void;
	validations?: ZodTypeAny;
	hasError?: boolean;
	errorMessage?: string | JSX.Element;
	onValidationError?: (error:VALIDATION_ERROR, clear:boolean) => void;
	onValidationSuccess?: (success:VALIDATION_ERROR, clear: boolean) => void;
	keepErrorSpace?: boolean;
	disabled?: boolean;
}

const CheckboxButton = (props:CheckboxProps) => {
	const {
		name,
		label,
		value,
		onChange,
		checked,
		indeterminate,
		activeColor = 'var(--brand-orange-default)',
		inActiveColor = 'var(--neutral-03)',
		inActiveBorderColor = 'var(--neutral-04)',
		disabledColor = 'var(--neutral-05)',
		onFocus,
		onBlur,
		validations,
		hasError,
		errorMessage,
		onValidationError,
		onValidationSuccess,
		keepErrorSpace,
		disabled
	} = props;

	const onFieldFocus = (e:FocusEvent<HTMLInputElement>) => {
		if (onFocus) onFocus(e);
	}
	const onFieldBlur = (e:FocusEvent<HTMLInputElement>) => {
		if (onBlur) onBlur(e);
		if (validations) {
			const valid = validations.safeParse(value);
			if (!valid.success) {
				const issues = [];
				for (let i=0, l=valid.error.issues.length; i<l; ++i) {
					issues.push(valid.error.issues[i].message);
				}
				const error = {
					field: name,
					issues
				};
				if (onValidationError) {
					onValidationError(error, false);
				}
			} else if (onValidationSuccess) {
				onValidationSuccess({ field: name }, true);
			}
		}
	}

	return (
		<>
			<label data-testid={'@Ofload-Checkbox-Label'} className={styles.inputContainer} style={{ cursor: disabled ? 'not-allowed' : 'default' }}>
				<input
					type={'checkbox'}
					value={value}
					checked={checked}
					onChange={onChange}
					className={styles.input}
					name={name}
					onFocus={onFieldFocus}
					onBlur={onFieldBlur}
					disabled={disabled}
				/>
				{checked && <CheckBoxIcon data-testid={`@Ofload-Checkbox-Checked`} className={styles.icons} style={{ color: disabled ? disabledColor : activeColor }} />}
				{!checked && !indeterminate && <>
					{!disabled && <CheckBoxOutlineBlankIcon data-testid={`@Ofload-Checkbox-UnChecked`} className={styles.icons} style={{ color: inActiveBorderColor }} />}
					{disabled && <CheckBoxDisabledUnchecked borderColor={inActiveBorderColor} fillColor={inActiveColor} />}
				</>}
				{!checked && indeterminate && <IndeterminateCheckBoxIcon data-testid={`@Ofload-Checkbox-Indeterminate`} className={styles.icons} style={{ color: disabled ? disabledColor : activeColor }} />}
				<Text>
					{label}
				</Text>
			</label>
			{hasError && <div className={styles.errorMsgContainer}>
				<Spacer size={5} />
				<Caption color={'var(--txt-error)'}>
					{errorMessage}
				</Caption>
			</div>}
			{keepErrorSpace && !hasError && <div className={styles.errorMsgContainer} />}
		</>
	);
}

CheckboxButton.Group = CheckboxGroup;

export const Checkbox = CheckboxButton;
